
import TextModule from "@/components/modules/TextModule.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { TextModule },
  setup() {
    return {
      slides: [
        {
          // UniverseSlideShowItems/images/pic19E70BA21521418F9013ADCF502C8EB0.jpg
          img: require("@/assets/slideshow-items/safety.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "Safety is top priority",
          text1:
            "The LEGO Group is dedicated to fostering kids’ creativity and learning, both with our toys and in the virtual playground. We comply with some of the world’s strictest safety standards for our toys, and are equally devoted to children’s well-being when they’re online.",
        },
        {
          // UniverseSlideShowItems/images/pic812CFD779C7BFFF13BB68E2E0D7E8965.jpg
          img: require("@/assets/slideshow-items/safety-2.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "How it's done!",
          text1:
            "The LEGO Group uses both advanced technology and a staff of professional moderators to protect children during game play. Our moderation team is moderating and monitoring LEGO® Universe at all times. That’s twenty-four hours, seven days a week!",
        },
        {
          // UniverseSlideShowItems/images/pic1A16313AFBBFD30CB41C422B5A8ED9B8.jpg
          img: require("@/assets/slideshow-items/safety-3.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "Chat!",
          text1:
            "Chatting with other players is fun, and to make sure it's also safe, there's a great in-game dictionary filled with fitting, useful words that enables kids to talk freely and creatively. Couple this with a well-trained team of human moderators, and we feel that we’re offering a service over and above industry best practices.",
          width: 310,
        },
        {
          // UniverseSlideShowItems/images/pic21A4B5684D04C837440B4C20E16BBDAF.jpg
          img: require("@/assets/slideshow-items/safety-build.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "Build!",
          text1:
            "LEGO® Universe offers kids some really cool creation tools, and the opportunity to show off their digital creations to other players in the game. To ensure that all submissions are suitable for children of all ages, we have real people with moderation expertise looking over all user-generated content before it gets published.",
          width: 280,
        },
        {
          // UniverseSlideShowItems/images/pic2D50B1C5814559E6BD827FC20A807266.jpg
          img: require("@/assets/slideshow-items/safety-report.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "Report!",
          text1:
            "If your child tells you that he or she is feeling uneasy, discuss it and find out all the details about the situation. Then please send a report to our moderation team, and we will open an investigation immediately. Parents have rightly come to expect extraordinarily high safety standards from the LEGO Group, and our constant ambition is to make LEGO® Universe an inclusive, fun and creative online destination for LEGO fans of any age to safely share in the LEGO experience.",
          width: 350,
        },
        {
          // UniverseSlideShowItems/images/pic93AF0E9D48837F5C5F9C648FE22EE442.jpg
          img: require("@/assets/slideshow-items/safety-mythrans.jpg"),
          headline2: "The Mythrans!",
          headline1: "About LEGO Universe:",
          text1:
            "The concept of safety is also built into the story of the game. In-game, moderation is governed by the Mythrans, a mysterious race of Minifigures with a history of protecting and doing good deeds for others. Through the Mythrans, kids are introduced to and educated about the role of a moderator.",
          width: 300,
        },
      ],
    };
  },
});
