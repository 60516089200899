<template>
  <div>
    <slideshow-module :slides="slides"></slideshow-module>
    <text-module>
      <div class="negative-row">
        <div class="onelines-text">
          <h1>Safety Tips</h1>
          <!-- UniverseDictionary/images/1033/pic892DF7BF39171C5D3A82A81B1C293C82.png -->
          <img
            class="safety-tips-image"
            src="@/assets/dictionary/safety-tip.png"
          />
        </div>
        <div class="t-list" id="SafetyTipsList">
          <p>
            <br />The LEGO Group complies with some of the world’s strictest
            safety standards for our toys, and is equally devoted to children’s
            well-being when they’re online. Below are the answers to the most
            frequently asked questions about safety in LEGO Universe.
          </p>

          <div class="t-row">
            <b><i></i></b><i><b></b></i><em></em>

            <div class="t-cell cell-listitem">
              <router-link to="safety-tips/173151" class="linkArrow"
                >Picking names in LEGO Universe</router-link
              >
            </div>

            <div class="t-cell cell-listitem">
              <router-link to="safety-tips/173152" class="linkArrow"
                >Getting into character</router-link
              >
            </div>

            <div class="t-cell cell-listitem">
              <router-link to="safety-tips/173153" class="linkArrow"
                >Being nice is cool!</router-link
              >
            </div>
          </div>

          <div class="t-row">
            <b><i></i></b><i><b></b></i><em></em>

            <div class="t-cell cell-listitem">
              <router-link to="safety-tips/173154" class="linkArrow"
                >Only the best is good enough</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </text-module>

    <div class="column-row">
      <div class="column column-medium first">
        <div class="column-top"><h1>Report a problem</h1></div>
        <div class="column-content">
          <div class="report-button-outer">
            <p>
              Should you or your child ever come across someone breaking the
              <router-link to="code-of-conduct">CODE OF CONDUCT</router-link> in the game,
              please send us a report. We will open an investigation and we will
              take appropriate action against any inappropriate behavior.
            </p>
            <div class="report-button">
              <a href="mailto:safetyreport@lego.com" class="button"
                ><span>Report a Problem</span></a
              >
            </div>
            <ul class="parentsTouts">
              <li></li>
              <li></li>
            </ul>
          </div>
          <!-- contenttemplating/universedictionary/images/1033/pic2f5b5c3b9d2acc50a9c25aca4b4c441e.png -->
          <img class="report-image" src="@/assets/dictionary/at-guy.png" />
          <div class="clear"></div>
        </div>
      </div>
      <div class="column column-small last">
        <div class="column-top"></div>
        <div class="column-content">
          <div class="negative-row">
            <h1>Educating our Players</h1>
            <p>
              The Mythrans are cool in-game safety characters with a history of
              protecting and doing good deeds for others. They make it fun for
              players to learn about
              <router-link to="safety-faq">moderation and safety</router-link>!
            </p>
            <ul class="parentsTouts">
              <li></li>
            </ul>
            <!-- contenttemplating/universedictionary/images/1033/picaa7af8869948cf7ac30b42ba4b2bbc01.png -->
            <img src="@/assets/dictionary/mythran.png" class="educate-image" />
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TextModule from "@/components/modules/TextModule.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { TextModule },
  setup() {
    return {
      slides: [
        {
          // UniverseSlideShowItems/images/pic19E70BA21521418F9013ADCF502C8EB0.jpg
          img: require("@/assets/slideshow-items/safety.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "Safety is top priority",
          text1:
            "The LEGO Group is dedicated to fostering kids’ creativity and learning, both with our toys and in the virtual playground. We comply with some of the world’s strictest safety standards for our toys, and are equally devoted to children’s well-being when they’re online.",
        },
        {
          // UniverseSlideShowItems/images/pic812CFD779C7BFFF13BB68E2E0D7E8965.jpg
          img: require("@/assets/slideshow-items/safety-2.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "How it's done!",
          text1:
            "The LEGO Group uses both advanced technology and a staff of professional moderators to protect children during game play. Our moderation team is moderating and monitoring LEGO® Universe at all times. That’s twenty-four hours, seven days a week!",
        },
        {
          // UniverseSlideShowItems/images/pic1A16313AFBBFD30CB41C422B5A8ED9B8.jpg
          img: require("@/assets/slideshow-items/safety-3.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "Chat!",
          text1:
            "Chatting with other players is fun, and to make sure it's also safe, there's a great in-game dictionary filled with fitting, useful words that enables kids to talk freely and creatively. Couple this with a well-trained team of human moderators, and we feel that we’re offering a service over and above industry best practices.",
          width: 310,
        },
        {
          // UniverseSlideShowItems/images/pic21A4B5684D04C837440B4C20E16BBDAF.jpg
          img: require("@/assets/slideshow-items/safety-build.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "Build!",
          text1:
            "LEGO® Universe offers kids some really cool creation tools, and the opportunity to show off their digital creations to other players in the game. To ensure that all submissions are suitable for children of all ages, we have real people with moderation expertise looking over all user-generated content before it gets published.",
          width: 280,
        },
        {
          // UniverseSlideShowItems/images/pic2D50B1C5814559E6BD827FC20A807266.jpg
          img: require("@/assets/slideshow-items/safety-report.jpg"),
          headline1: "About LEGO Universe:",
          headline2: "Report!",
          text1:
            "If your child tells you that he or she is feeling uneasy, discuss it and find out all the details about the situation. Then please send a report to our moderation team, and we will open an investigation immediately. Parents have rightly come to expect extraordinarily high safety standards from the LEGO Group, and our constant ambition is to make LEGO® Universe an inclusive, fun and creative online destination for LEGO fans of any age to safely share in the LEGO experience.",
          width: 350,
        },
        {
          // UniverseSlideShowItems/images/pic93AF0E9D48837F5C5F9C648FE22EE442.jpg
          img: require("@/assets/slideshow-items/safety-mythrans.jpg"),
          headline2: "The Mythrans!",
          headline1: "About LEGO Universe:",
          text1:
            "The concept of safety is also built into the story of the game. In-game, moderation is governed by the Mythrans, a mysterious race of Minifigures with a history of protecting and doing good deeds for others. Through the Mythrans, kids are introduced to and educated about the role of a moderator.",
          width: 300,
        },
      ],
    };
  },
});
</script>
